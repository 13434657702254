import React, { useState } from "react";
import RegisterForm from "./components/RegisterForm";

const Home = () => {
  const [triggerForm, setTriggerForm] = useState(false);

  return (
    <>
      <div className="mymd:flex items-center flex-col w-[100vw] mymd:w-[100%]  overflow-auto px-3 mb-[30px]">
        {/* {cover image} */}

        <div className="mymd:w-[600px] mymd:h-[200px] rounded-[10px] my-[15px]  mymd:px-0 border-gray-300 w-[100%]">
          <img
            src="/coverImage.jpg"
            alt=""
            className="w-full h-full rounded-[10px] object-fill"
          />
        </div>

        {/* {IAPEN Description} */}
        <div className="mymd:w-[600px] flex h-full flex-col bg-white mymd:py-6 rounded-[10px] mymd:border border-gray-300 w-[100%] mb-[50px]">
          <div className="mymd:px-4 px-0">
            <p className="text-[21px] pt-0 font-[600] text-gray-900 text-center">
              CYBERX BAHRAIN SUMMIT & AWARDS 2024
            </p>

            <div className="rounded-[10px] border p-2 border-gray-300 my-2">
              <p className="text-[14px] font-[500]">28 February 2024</p>
              <p className="text-[14px] font-[500] pt-2">8:30 AM - 4:00 PM</p>
              <p className="text-[14px] font-[500] pt-2">
                <span className="font-[600]">Venue:</span> InterContinental
                Bahrain, an IHG Hotel, King Faisal Hwy, Manama, Bahrain
              </p>
            </div>

            <div className="text-[14px] font-[500] pt-2">
              <p className="text-[15px] font-[600] ml-[5px]">
                Before you begin your registration,
              </p>
              <ol className=" list-decimal m-2.5">
                <li className="mb-2 ml-1">
                  Online registration for CYBERX Bahrain is open to
                  professionals from end-user companies only. To register please
                  complete the following registration form. Once your
                  registration is received, someone from our team will reach out
                  to you to confirm your registration.
                </li>
                <li className="mb-2 ml-[5px]">
                  Note : If you work for a company which provides cyber security
                  and related software products, solutions and services or a
                  consultant, re-seller or distributor OR work in a sales,
                  marketing, business development, product and service delivery
                  role please email{" "}
                  <span
                    className="cursor-pointer underline text-primary"
                    onClick={() =>
                      window.open(`mailto:enquiry@bconnectglobal.com`, "_blank")
                    }
                  >
                    enquiry@bconnectglobal.com
                  </span>{" "}
                  for further information of how you can be involved in this
                  event.
                </li>
                <li className="mb-2 ml-[5px]">
                  Press and Journalists may reach our marketing team at{" "}
                  <span
                    className="cursor-pointer underline text-primary"
                    onClick={() =>
                      window.open(`mailto:husna@bconnectglobal.com`, "_blank")
                    }
                  >
                    husna@bconnectglobal.com
                  </span>{" "}
                  (Kindly Ensure Correct Spelling, Capitalization and Spacing
                  Where Necessary)
                </li>
              </ol>
            </div>
            <p className="pt-0 font-[500] ml-1">
              To join the event, please register below.
            </p>

            {/* {registration buttons} */}
            <div className="flex flex-col mymd:flex-row gap-5 items-center text-white font-[500] text-[17px] mt-3.5">
              <div
                type="button"
                onClick={() => setTriggerForm(true)}
                // onClick={() => {
                //   let code = localStorage.getItem("code");
                //   if (code === "1357") {
                //     setTriggerIndividualForm(true);
                //   } else {
                //     dispatch(
                //       setShowToast({
                //         message:
                //           "We are under maintenance, hold on we are going back live in few hours.",
                //         category: "danger",
                //       })
                //     );
                //   }
                // }}
                className="w-[70%] mymd:w-full flex mymd:h-[40px] h-[45px]  justify-center items-center bg-white rounded-[10px] gap-1 cursor-pointer text-primary border-2 border-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="#230C54"
                    d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                  />
                </svg>
                Register
              </div>
            </div>
          </div>
        </div>
        {
          <>
            <RegisterForm
              setTriggerPopup={setTriggerForm}
              triggerPopup={triggerForm}
            />
          </>
        }
      </div>
    </>
  );
};

export default Home;
