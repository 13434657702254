import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import countryCodes from "../partials/CountryCodes.json";
import CountryCode from "../common/CountryCode";
import Select from "../common/Select";
import {
  HeardFrom,
  InterestedIn,
  TypeOfAttendee,
} from "../partials/AllOptions";
import CountryDropdown from "../common/CountryDropdown";
import { setShowToast } from "../redux/toastSlice";
import { useDispatch } from "react-redux";
import axios from "axios";

const Form = ({ setTriggerPopup, triggerPopup }) => {
  const [countryCode, setCountryCode] = useState("+973");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [hostCountry, setHostCountry] = useState([]);
  const [countryResult, setCountryResult] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [eventId, setEventId] = useState("659fb71996619de5add8fc9e");
  const eventId = process.env.REACT_APP_EVENT_ID;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    if (!eventId) {
      dispatch(
        setShowToast({
          message: "Event not found",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (
      data?.participationConfirmation !== "Yes" ||
      data?.termsConditionsAndPrivacyPolicyAgreement !== "Yes"
    ) {
      dispatch(
        setShowToast({
          message: "Please accept terms and conditions",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const matchingCountryCode = countryCodes.find(
      (country) => country.dial_code === countryCode
    );

    if (!matchingCountryCode) {
      dispatch(
        setShowToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );
      setIsSubmitting(false);

      return;
    }

    const regex = /^[\d]{10}$/;
    if (!/^\d+$/.test(data?.mobile?.trim())) {
      dispatch(
        setShowToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      setIsSubmitting(false);

      return;
    }

    let finalData = {
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      email: data.email.toLowerCase().trim(),
      countryCode: countryCode,
      organization: data?.companyName?.trim(),
      jobTitle: data?.occupation?.trim(),
      mobile: data?.mobile?.trim(),
      country: countryResult,
      eventId: eventId,
      additionalData: {
        interested_in: value1?.label || "",
        heard_from: value2?.label || "",
        type_of_attendee: value3?.label || "",
        event_location_preference: hostCountry?.name?.trim() || "",
        data_sharing_consent: data?.dataSharingConsent || "No",
        participation_confirmation: data?.participationConfirmation,
        terms_conditions_and_privacypolicy_agreement:
          data?.termsConditionsAndPrivacyPolicyAgreement,
      },
    };

    const verifiedUser = await userValidation(finalData);
    if (!verifiedUser) {
      dispatch(
        setShowToast({
          message: "You have already registered for the event",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/attendee/eventregister`, {
        ...finalData,
      })
      .then(function (response) {
        setSubmitStatus("success");
        reset();
        setIsSubmitting(false);
      })
      .catch(function (error) {
        let message = error.response.data.message;
        reset();
        setIsSubmitting(false);
        setSubmitStatus("failed");
        if (message === "You have already registered for this event") {
          dispatch(
            setShowToast({
              message: "You have already registered for this event",
              category: "danger",
            })
          );
        } else if (error.response.status === 409) {
          dispatch(
            setShowToast({
              message: `Your registration for this event is ${message}.`,
              category: "danger",
            })
          );
        } else {
          dispatch(
            setShowToast({
              message: `Sorry, something went wrong`,
              category: "danger",
            })
          );
        }

        setIsSubmitting(false);
      });
  };

  const isYahooEmail = (email) => {
    return !/@yahoo\./i.test(email);
  };

  const userValidation = async (users) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/checkGroup`,
        {
          eventId: eventId,
          users: [users],
        }
      );

      if (response?.data?.success === false) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      dispatch(
        setShowToast({
          message: `Sorry, something went wrong`,
          category: "danger",
        })
      );
      return false;
    }
  };

  return (
    <>
      {submitStatus === null ? (
        <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="mymd:flex justify-between ">
              <div className="mymd:w-[244px] mb-[27px] relative">
                <label
                  className="block text-[15px] font-[400] mb-1 text-gray-500"
                  htmlFor="firstName"
                >
                  First Name <span className="text-rose-500">*</span>
                </label>
                <input
                  id="firstName"
                  className="form-input w-[100%]"
                  type="text"
                  required
                  placeholder="First Name"
                  {...register("firstName", {
                    required: "First Name is required",
                    validate: (value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue === "") {
                        return "Please enter a valid first name";
                      }
                      return true;
                    },
                  })}
                />
                {errors?.firstName && (
                  <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                    {errors?.firstName && <p>{errors?.firstName?.message}</p>}
                  </p>
                )}
              </div>

              <div className="mymd:w-[243px] mb-[27px] relative">
                <label
                  className="block text-[15px] font-[400] mb-1 text-gray-500"
                  htmlFor="lastName"
                >
                  Last Name <span className="text-rose-500">*</span>
                </label>
                <input
                  id="lastName"
                  className="form-input w-full"
                  type="text"
                  required
                  placeholder="Last Name"
                  {...register("lastName", {
                    required: "Last Name is required",
                    validate: (value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue === "") {
                        return "Please enter a valid last name";
                      }
                      return true;
                    },
                  })}
                />
                {errors?.lastName && (
                  <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                    {errors?.lastName && <p>{errors?.lastName?.message}</p>}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="mobile"
              >
                Contact Number <span className="text-rose-500">*</span>
              </label>
              <div className="flex gap-2 items-center mb-[27px] relative">
                <CountryCode
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  allCountryCode={allCountryCode}
                  setAllCountryCode={setAllCountryCode}
                />

                <div className="w-full">
                  <input
                    id="mobile"
                    className="form-input w-full"
                    type="tel"
                    required
                    // maxLength={10}
                    placeholder="Contact Number"
                    inputMode="numeric"
                    {...register("mobile", {
                      required: "Field cannot be empty",
                      validate: (value) => {
                        const trimmedValue = value.trim();
                        if (trimmedValue === "") {
                          return "Please enter a valid number";
                        }

                        if (!/^\d+$/.test(trimmedValue)) {
                          return "Please enter a valid number";
                        }

                        return true;
                      },
                    })}
                  />
                  {errors?.mobile && (
                    <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                      {errors?.mobile && <p>{errors?.mobile?.message}</p>}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="mymd:w-[500px] mb-[27px] relative">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="email"
              >
                Email <span className="text-rose-500">*</span> <br />
              </label>

              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    validEmail: (value) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        value
                      ) || "Invalid email format",
                    notYahooEmail: (value) =>
                      isYahooEmail(value) ||
                      "Sorry, we do not support Yahoo emails, for assistance contact: +91 8591076985",
                  },
                }}
                render={({ field }) => (
                  <div>
                    <input
                      type="text"
                      {...field}
                      className="form-input w-full"
                      placeholder="Email"
                    />
                    <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                      {errors?.email && errors?.email?.message}
                    </p>
                  </div>
                )}
              />
            </div>

            <div className="mymd:w-[500px] mb-[27px] relative">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="companyName"
              >
                Company <span className="text-rose-500">*</span>
              </label>
              <input
                id="companyName"
                className="form-input w-full"
                type="text"
                required
                placeholder="Company Name"
                {...register("companyName", {
                  required: "This field is required",
                  validate: (value) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue === "") {
                      return "Please enter a valid text";
                    }
                    return true;
                  },
                })}
              />
              {errors?.companyName && (
                <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                  {errors?.companyName && <p>{errors?.companyName?.message}</p>}
                </p>
              )}
            </div>

            <div className="mymd:w-[500px] mb-[27px] relative">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="occupation"
              >
                Job Title <span className="text-rose-500">*</span>
              </label>
              <input
                id="occupation"
                className="form-input w-full"
                type="text"
                required
                placeholder="Job Title "
                {...register("occupation", {
                  required: "This field is required",
                  validate: (value) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue === "") {
                      return "Please enter a valid text";
                    }
                    return true;
                  },
                })}
              />
              {errors?.occupation && (
                <p className="absolute text-red-500 text-[11px] font-[500] mt-[2px]">
                  {errors?.occupation && <p>{errors?.occupation?.message}</p>}
                </p>
              )}
            </div>

            <CountryDropdown
              countryResult={countryResult}
              setCountryResult={setCountryResult}
              label={"Country"}
              required={true}
            />

            <div className=" text-[15px] font-[400] text-gray-500 mb-[27px]">
              <label
                htmlFor="occupation"
                className="block text-[15px] font-[400] mb-1 text-gray-500"
              >
                Interested In
              </label>
              <Select
                id="occupation"
                options={InterestedIn}
                value={value1}
                onChange={(data) => {
                  setValue1(data);
                }}
              />
            </div>

            <div className=" text-[15px] font-[400] text-gray-500 mb-[27px]">
              <label
                htmlFor="heardFrom"
                className="block text-[15px] font-[400] mb-1 text-gray-500"
              >
                Where did you here about us?
              </label>
              <Select
                id="heardFrom"
                options={HeardFrom}
                value={value2}
                onChange={(data) => {
                  setValue2(data);
                }}
              />
            </div>

            <div className=" text-[15px] font-[400] text-gray-500 mb-[27px]">
              <label
                htmlFor="typeOfAttendee"
                className="block text-[15px] font-[400] mb-1 text-gray-500"
              >
                Type of Attendee?
              </label>
              <Select
                id="typeOfAttendee"
                options={TypeOfAttendee}
                value={value3}
                onChange={(data) => {
                  setValue3(data);
                }}
              />
            </div>

            <CountryDropdown
              countryResult={hostCountry}
              setCountryResult={setHostCountry}
              label={
                "Which other country/region would you like us to host CYBERX?"
              }
              required={false}
            />

            <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5 mymd:w-[500px]">
              <input
                type="checkbox"
                className="form-checkbox mt-1 min-w-[17px]"
                required
                value="Yes"
                id="participationConfirmation"
                {...register("participationConfirmation")}
              />
              <label
                className="text-[13px] font-[600] cursor-pointer "
                htmlFor="participationConfirmation"
              >
                I hereby confirm my participation to attend the following event
                CYBERX Bahrain SUMMIT 2024 (28th FEBRUARY, 2024){" "}
                <span className="text-rose-500">*</span>
              </label>
            </div>

            <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5 mymd:w-[500px]">
              <input
                type="checkbox"
                className="form-checkbox mt-1 min-w-[17px]"
                value="Yes"
                id="dataSharingConsent"
                {...register("dataSharingConsent")}
              />
              <label
                className="text-[13px] font-[600] cursor-pointer "
                htmlFor="dataSharingConsent"
              >
                I agree to my contact details to be passed to the event's
                sponsor/ patners to contact me as a follow-up to my
                participation at the event. I understand that any use of such
                data is governed by the respective sponsors/ partners privacy
                policy.{" "}
              </label>
            </div>

            <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5 mymd:w-[500px]">
              <input
                type="checkbox"
                className="form-checkbox mt-1 min-w-[17px]"
                required
                value="Yes"
                id="termsConditionsAndPrivacyPolicyAgreement"
                {...register("termsConditionsAndPrivacyPolicyAgreement")}
              />
              <label
                className="text-[13px] font-[600] cursor-pointer "
                htmlFor="termsConditionsAndPrivacyPolicyAgreement"
              >
                I agree to the general{" "}
                <span
                  className="cursor-pointer underline text-primary"
                  onClick={() =>
                    window.open(
                      `https://www.bconnectglobal.com/public/payment-policies`,
                      "_blank"
                    )
                  }
                >
                  Terms and Conditions
                </span>{" "}
                and{" "}
                <span
                  className="cursor-pointer underline text-primary"
                  onClick={() =>
                    window.open(
                      `https://www.bconnectglobal.com/public/privacy-policy`,
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </span>{" "}
                of BCONNECT GLOBAL. <span className="text-rose-500">*</span>
              </label>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full flex h-[40px] justify-center items-center bg-primary py-0 rounded-[10px] text-white font-[500] text-[17px] mt-6 ${
                isSubmitting ? "cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      ) : submitStatus === "success" ? (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="mymd:h-[350px] mymd:w-[600px] w-full flex flex-col items-center mymd:border justify-center px-6 rounded-sm mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
            <svg
              width="90"
              height="90"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_118_2684)">
                <path
                  d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM20 34L10 24L12.82 21.18L20 28.34L35.18 13.16L38 16L20 34Z"
                  fill="#2ECC71"
                />
              </g>
              <defs>
                <clipPath id="clip0_118_2684">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-[26px] mt-[10px] font-[500] text-center">
              Registration successful
            </span>

            <p className="mt-[10px] font-[500] text-center">
              Thank you! Your registrations was successful, a confirmation mail
              has been sent to your email.
            </p>
          </div>
        </div>
      ) : submitStatus === "failed" ? (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="mymd:h-[350px] mymd:w-[600px] w-full flex flex-col items-center mymd:border justify-center px-6 rounded-sm mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
            <svg
              width="90"
              height="90"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 0C7.8225 0 0 7.8225 0 17.5C0 27.1775 7.8225 35 17.5 35C27.1775 35 35 27.1775 35 17.5C35 7.8225 27.1775 0 17.5 0ZM26.25 23.7825L23.7825 26.25L17.5 19.9675L11.2175 26.25L8.75 23.7825L15.0325 17.5L8.75 11.2175L11.2175 8.75L17.5 15.0325L23.7825 8.75L26.25 11.2175L19.9675 17.5L26.25 23.7825Z"
                fill="#E74C3C"
              />
            </svg>
            <span className="text-[26px] mt-[10px] font-[500] text-center">
              Failed to Register
            </span>

            <p className="mt-[10px] font-[500] text-center">
              Registration failed. Please try again later.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Form;
